import React from 'react'
import {BrowserRouter, Routes, Route, useLocation} from 'react-router-dom'
import {useLayoutEffect} from 'react';
import Header from './components/Header';
import Home from './pages/Home';
//import Reservation from './components/Reservation';

const Wrapper = ({children}) => {
    const location = useLocation();
    useLayoutEffect(() => {
        document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);

    return children
}

function App() {
  
  return (
    <BrowserRouter>
        <Wrapper>
            <Routes>
                <Route path="/" element={<Header />}>
                    <Route index element={<Home />} />                                                         
                    {/*<Route path="reservation2" element={<Reservation />} />     */}
                   
                </Route>   
            </Routes>
        </Wrapper>
      </BrowserRouter>
  );
}

export default App;